@font-face {
  font-family: Dosis-ExtraLight;
  src: url('../assets/fonts/Dosis-ExtraLight.ttf');
}
@font-face {
  font-family: Dosis-Light;
  src: url('../assets/fonts/Dosis-Light.ttf');
}
@font-face {
  font-family: Dosis-Regular;
  src: url('../assets/fonts/Dosis-Regular.ttf');
}
@font-face {
  font-family: Dosis-Medium;
  src: url('../assets/fonts/Dosis-Medium.ttf');
}
@font-face {
  font-family: Dosis-SemiBold;
  src: url('../assets/fonts/Dosis-SemiBold.ttf');
}
@font-face {
  font-family: Dosis-Bold;
  src: url('../assets/fonts/Dosis-Bold.ttf');
}
@font-face {
  font-family: Dosis-ExtraBold;
  src: url('../assets/fonts/Dosis-ExtraBold.ttf');
}

body {
  padding: 0;
  margin: 0;
  font-family: Dosis-Regular;
  font-size: 16px;
}

.op-app,
.op-body {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  min-height: 100vh;
}

.op-body {
  top: 50px;
}

.op-body .op-body-contenedor {
  padding: 0 100px;
  position: relative;
  background-color: #ffffff;
}

.op-boton {
  padding: 10px;
  background-color: black;
  color: #ffffff;
  text-decoration: none;
  border-radius: 10px;
  display: block;
  text-align: center;
  font-size: 20px;
  margin-top: 10px;
  font-family: Dosis-Medium;
}

@media only screen and (max-width: 885px) {
  .op-body .op-body-contenedor {
    padding: 0 50px;
  }
}

@media (max-width: 576px) {
  .op-body .op-body-contenedor {
    padding: 0 30px;
  }
}
