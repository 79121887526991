.op-fondo {
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      #ffffff 100%
    ),
    url('../../assets/images/fondo.jpg');
  height: calc(100vh - 50px);
  left: -100px;
  width: calc(100% + 200px);
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;
}

.op-fondo label {
  text-transform: uppercase;
  font-family: Dosis-ExtraBold;
  font-size: 60px;
  justify-self: center;
  align-self: flex-end;
}

.op-fondo span {
  font-family: Dosis-SemiBold;
  font-size: 30px;
  flex-basis: 100%;
  justify-self: flex-start;
  align-self: flex-end;
  padding-left: 100px;
}

.op-info span {
  font-family: Dosis-SemiBold;
  font-size: 20px;
}

.op-info p {
  font-size: 16px;
  text-align: justify;
}

@media (max-width: 576px) {
  .op-fondo {
    left: -30px;
    width: calc(100% + 60px);
  }

  .op-fondo label {
    font-family: Dosis-Bold;
    font-size: 35px;
    text-align: center;
  }

  .op-fondo span {
    font-size: 25px;
    padding-left: 30px;
  }
}
