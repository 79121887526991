.op-empresa {
  display: grid;
  grid-template-columns: 1fr 1fr;
  position: relative;
}
.op-fondo-empresa {
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url('../../assets/images/fondoempresa.png');
  height: calc(100vh - 50px);
  width: calc(100% + 100px);
}

.op-empresa .op-titulo {
  align-self: center;
}

.op-empresa .op-titulo label {
  font-family: Dosis-ExtraBold;
  font-size: 60px;
}

.op-beneficios {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  column-gap: 20px;
  row-gap: 20px;
  background-color: #f3f5f9;
  padding: 50px 0;
}

.op-beneficios .op-card {
  background-color: #ffffff;
  border-radius: 10px;
  display: flex;
  gap: 10px;
  border: 1px solid #c2c2c2;
  padding: 0 10px;
}

.op-beneficios .op-card .icon {
  background-repeat: no-repeat;
  background-size: 100%;
  text-decoration: none;
  width: 100px;
  height: 100px;
  display: block;
  margin: 20px 0;
  align-self: center;
}

.op-beneficios .op-card .icon.graph {
  background-image: url('../../assets/images/grafica.svg');
}

.op-beneficios .op-card .icon.startup {
  background-image: url('../../assets/images/startup.svg');
}

.op-beneficios .op-card .text {
  align-self: center;
  font-family: Dosis-SemiBold;
  font-size: 25px;
}

.op-requisitos label {
  font-family: Dosis-Bold;
  font-size: 30px;
  text-align: center;
  display: block;
}

.op-requisitos span {
  font-family: Dosis-SemiBold;
  font-size: 20px;
}
.op-requisitos p {
  font-size: 16px;
  text-align: justify;
}

@media (max-width: 576px) {
  .op-empresa {
    grid-template-columns: 1fr;
    z-index: 1;
  }

  .op-empresa .op-fondo-empresa {
    position: absolute;
    background-image: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) 0%,
        #ffffff 100%
      ),
      url('../../assets/images/fondoempresa.png');
    height: 290px;
  }

  .op-empresa .op-titulo {
    z-index: 2;
    padding-top: 20px;
    font-size: 30px;
  }

  .op-beneficios {
    grid-template-columns: 1fr;
  }
}
