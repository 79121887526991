.op-footer {
  position: relative;
  bottom: 0;
  width: 100%;
  min-height: 50px;
  background-color: #2a3443;
  display: flex;
  justify-content: space-around;
}

.op-footer > div {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #ffffff;
}

@media (max-width: 576px) {
  .op-footer {
    flex-direction: column;
  }
}
