.op-fondo-promocion {
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      #ffffff 100%
    ),
    url('../../assets/images/fondo.jpg');
  height: calc(100vh - 50px);
  left: -100px;
  width: calc(100% + 200px);
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;
}

.op-fondo-promocion label {
  text-transform: uppercase;
  font-family: Dosis-ExtraBold;
  font-size: 60px;
  justify-self: center;
  align-self: flex-end;
}

.op-promociones {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto;
  gap: 20px;
  margin-bottom: 20px;
}

.op-promociones .op-promocion-item {
    border-radius: 10px;
    border: 1px solid #ccc;
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, .75);
    align-items: flex-end;
    cursor: pointer;
}

.op-promociones .op-promocion-item label:first-child {
    align-self: center;
    font-size: 20px;
}

.op-promociones .op-promocion-item img {
    align-self: center;
    width: 200px;
    height: 150px;
}

@media (max-width: 576px) {
    .op-fondo-promocion {
        left: -30px;
        width: calc(100% + 60px);
      }

    .op-fondo-promocion label {
        font-family: Dosis-Bold;
        font-size: 40px;
        text-align: center;
      }

    .op-promociones {
      grid-template-columns: 1fr;
    }
}