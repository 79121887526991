.op-content-confirmar {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.op-content-confirmar .op-confirmar {
  flex-basis: 30%;
  border: 1px solid #cccccc;
  border-radius: 10px;
  padding: 50px 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.op-content-confirmar .op-confirmar h1 {
  text-align: center;
}

.op-content-confirmar .op-confirmar h3,
.op-content-confirmar .op-confirmar h5 {
  text-align: center;
  color: #999999;
}

@media (max-width: 881px) {
  .op-content-confirmar .op-confirmar {
    flex-basis: 50%;
  }
}

@media (max-width: 665px) {
  .op-content-confirmar .op-confirmar {
    flex-basis: 80%;
  }
}
