.op-fondo-soporte {
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) 0%,
        #ffffff 100%
      ),
      url('../../assets/images/soporte.jpg');
    height: calc(100vh - 50px);
    left: -100px;
    width: calc(100% + 200px);
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    justify-items: center;
  }
  
  .op-fondo-soporte label {
    text-transform: uppercase;
    font-family: Dosis-ExtraBold;
    font-size: 60px;
    align-self: flex-end;
  }
  
  .op-fondo-soporte span {
    font-family: Dosis-SemiBold;
    font-size: 15px;
    flex-basis: 100%;
    align-self: center;
    padding-left: 100px;
  }

.op-fondo-soporte .op-redes {
    display: flex;
    gap: 10px;
    font-family: Dosis-SemiBold;
    font-size: 18px;
}

.op-fondo-soporte .op-redes a {
    text-decoration: none;
    color: #000;
  }