.op-inicio {
  position: relative;
  background-color: #3644af;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  left: -100px;
  width: calc(100% + 200px);
}

.op-inicio .op-content,
.op-servicios .op-content {
  width: 40%;
  text-align: center;
  grid-area: header;
  color: #ffffff;
}

.op-inicio .op-content h1,
.op-servicios .op-content h1 {
  font-family: Dosis-ExtraBold;
  font-size: 50px;
}

.op-inicio .code {
  position: absolute;
  left: 0;
  top: 0;
  width: 40%;
  height: 50%;
}

.op-inicio .mobile {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 40%;
  height: 50%;
}

.op-servicios {
  display: flex;
  flex-direction: column;
}

.op-servicios .op-content {
  width: 100%;
  color: #333333;
  margin-bottom: 20px;
}

.op-servicios .op-items {
  display: flex;
  justify-content: space-between;
}

.op-servicios .op-items .op-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 30%;
  border-radius: 8px;
  padding: 10px 15px;
  color: #ffffff;
}
.op-servicios .op-items .op-item label {
  font-family: Dosis-Bold;
  font-size: 18px;
}

.op-servicios .op-items .op-item.web {
  background-color: #243443;
}

.op-servicios .op-items .op-item.mobile {
  background-color: #3644af;
}

.op-servicios .op-items .op-item.consulting {
  background-color: #1fb6ea;
}

.op-servicios .op-items .op-item .icon {
  background-repeat: no-repeat;
  background-size: 100%;
  text-decoration: none;
  width: 100px;
  height: 100px;
  display: block;
  margin-bottom: 20px;
}

.op-servicios .op-items .op-item.web .icon {
  background-image: url('../../assets/images/web.svg');
}

.op-servicios .op-items .op-item.mobile .icon {
  background-image: url('../../assets/images/mobile.svg');
}

.op-servicios .op-items .op-item.consulting .icon {
  background-image: url('../../assets/images/team.svg');
}

.op-clientes {
  display: flex;
  justify-content: space-around;
}

.op-clientes a.client {
  background-position: center;
  background-repeat: no-repeat;
  display: block;
  width: 250px;
  height: 50px;
  padding: 80px 0;
}

.op-clientes a.client.emsag {
  background-image: url('../../assets/images/emsag.svg');
}

.op-clientes a.client.patco {
  background-image: url('../../assets/images/patco.png');
}

.op-contacto {
  position: relative;
  background-color: #3644af;
  padding: 40px 120px 40px 20px;
  width: calc(100% - 40px);
}

.op-contacto .op-contenedor {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.op-contacto .op-contenedor .texto {
  position: relative;
  flex-basis: 100%;
  font-family: Dosis-Regular;
  color: #ffffff;
}

.op-contacto .op-contenedor .texto span {
  color: orange;
  font-size: 13px;
  position: absolute;
  right: 0;
  top: -17px;
}

.op-contacto .op-contenedor .texto.col-2 {
  flex-basis: calc(50% - 10px);
  max-width: calc(50% - 10px);
}

.op-contacto .op-contenedor .texto label {
  position: absolute;
  left: 10px;
  top: 15px;
  font-size: 16px;
  font-family: Dosis-SemiBold;
  color: #ffffff;
  pointer-events: none;
  font-size: 14px;
  z-index: 1;
  transition: 0.3s ease all;
  -moz-transition: 0.3s ease all;
  -webkit-transition: 0.3s ease all;
}

.op-contacto .op-contenedor .texto input,
.op-contacto .op-contenedor .texto textarea {
  padding: 15px 10px 10px 10px;
  font-family: Dosis-Regular;
  border: none;
  resize: none;
  width: available;
  width: -moz-available;
  width: -webkit-fill-available;
  background-color: #404eb3;
  color: #ffffff;
  border-radius: 5px;
  z-index: 0;
}

.op-contacto .op-contenedor .texto input:focus,
.op-contacto .op-contenedor .texto textarea:focus {
  outline: none;
}

.op-contacto .op-contenedor .texto input:focus ~ label,
.op-contacto .op-contenedor .texto input:valid ~ label,
.op-contacto .op-contenedor .texto textarea:focus ~ label,
.op-contacto .op-contenedor .texto textarea:valid ~ label {
  top: -1px;
  left: 8px;
  font-size: 12px;
  color: #999999;
}

.op-contacto .op-contenedor .texto.accion {
  display: flex;
  justify-content: flex-end;
}

.op-contacto .op-contenedor button {
  text-transform: uppercase;
  background-color: #1fb6e9;
  color: #ffffff;
  padding: 20px;
  border: none;
  border-radius: 5px;
  font-family: Dosis-ExtraBold;
  letter-spacing: 3px;
  cursor: pointer;
}

.op-contacto .op-contenedor button:disabled {
  background-color: #888888;
  cursor: not-allowed;
}

@media only screen and (max-width: 885px) {
  .op-inicio {
    flex-direction: column;
    left: -50px;
    width: calc(100% + 100px);
  }

  .op-inicio .code {
    height: 30%;
    width: 30%;
  }

  .op-inicio .mobile {
    width: 30%;
    height: 30%;
  }

  .op-contacto {
    padding: 40px 60px 40px 20px;
    width: calc(100% - 30px);
  }

  .op-contacto .op-contenedor .texto.col-2 {
    flex-basis: 100%;
    max-width: 100%;
  }
}

@media (max-width: 576px) {
  .op-inicio {
    flex-direction: column;
    left: -30px;
    width: calc(100% + 60px);
  }

  .op-inicio .op-content {
    width: calc(100% - 40px);
  }

  .op-inicio .code {
    position: relative;
    height: 30%;
  }

  .op-inicio .mobile {
    width: 0;
    height: 0;
  }

  .op-servicios .op-items {
    flex-direction: column;
    align-items: center;
  }

  .op-servicios .op-items .op-item {
    width: calc(100% - 30px);
    margin-bottom: 15px;
  }

  .op-clientes {
    flex-direction: column;
    align-items: center;
  }

  .op-contacto {
    padding: 40px 20px;
    width: calc(100% - 40px);
  }
}
