.op-content-loader {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffffb3;
  z-index: 50;
}

.op-content-loader .op-loader {
  position: absolute;
  display: flex;
  width: 70px;
}

.op-content-loader .op-loader span {
  width: 10px;
  height: 60px;
  background-color: #ffffff;
  margin: 0 1px;
  animation: loading-animado 800ms ease-in-out infinite;
}

.op-content-loader .op-loader span:nth-child(1) {
  background-color: #2eeca8;
  animation-delay: 700ms;
}

.op-content-loader .op-loader span:nth-child(2) {
  background-color: #eade3d;
  animation-delay: 600ms;
}

.op-content-loader .op-loader span:nth-child(3) {
  background-color: #55acee;
  animation-delay: 500ms;
}

.op-content-loader .op-loader span:nth-child(4) {
  background-color: #ec902e;
  animation-delay: 400ms;
}

.op-content-loader .op-loader span:nth-child(5) {
  background-color: #09b7bf;
  animation-delay: 300ms;
}

.op-content-loader .op-loader span:nth-child(6) {
  background-color: #ea3d8c;
  animation-delay: 200ms;
}

@keyframes loading-animado {
  0%,
  60%,
  100% {
    transform: scaleY(0.05);
  }
  35% {
    transform: scaleY(1);
  }
}
