.op-bloque-contenedor {
  display: flex;
  margin-bottom: 50px;
  position: relative;
  background-color: #f3f5f9;
}

.op-bloque-contenedor > div {
  flex-basis: 50%;
}

.op-bloque-contenedor .overlay {
  position: absolute;
  background-color: #ffffff;
  left: -100px;
  right: -100px;
  top: 0;
  bottom: 0;
}

.op-bloque-contenedor.reverse {
  background-color: #ffffff;
}

.op-bloque-contenedor div.texto {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  font-family: Dosis-Bold;
  font-size: 50px;
  z-index: 1;
}

.op-bloque-contenedor div.texto div {
  padding-right: 30px;
  padding-left: 0;
}

.op-bloque-contenedor.reverse div.texto div {
  padding-left: 30px;
  padding-right: 0;
}

.op-bloque-contenedor.reverse div.texto {
  order: 1;
}

.op-bloque-contenedor div.imagen {
  animation: bloque-imagen-animado 800ms ease-in;
}

@media only screen and (max-width: 885px) {
  .op-bloque-contenedor .overlay {
    left: -50px;
    right: -50px;
  }
}

@media (max-width: 576px) {
  .op-bloque-contenedor {
    flex-direction: column;
  }

  .op-bloque-contenedor .overlay {
    left: -30px;
    right: -30px;
  }

  .op-bloque-contenedor div.texto {
    order: 1;
    font-size: 35px;
  }

  .op-bloque-contenedor.reverse div.texto div {
    padding: 20px 0;
  }

  .op-bloque-contenedor div.imagen {
    order: 2;
  }
}

@keyframes bloque-imagen-animado {
  0% {
    transform: rotate(15deg) translateX(-200px);
  }
  100% {
    transform: rotate(0deg) translateX(0px);
  }
}