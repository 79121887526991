.op-header {
  position: fixed;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 10px #a7a7a71a;
  height: 50px;
  width: 100%;
  z-index: 200;
}

.op-header .op-header-contenedor {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 100px;
  box-shadow: 2px -2px 9px #888888;
}

.op-header .op-logo {
  font-size: 25px;
  font-family: Dosis-Bold;
  height: 50px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.op-header .op-header-contenedor ul {
  text-decoration: none;
  list-style: none;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.op-header .op-header-contenedor ul li {
  display: flex;
  padding: 0 10px;
  justify-content: center;
}

.op-header .op-header-contenedor ul li a {
  text-decoration: none;
  color: #42495b;
  padding: 5px;
  text-align: center;
  position: relative;
}

.op-header .op-header-contenedor ul li a.active,
.op-header .op-header-contenedor ul li a:hover {
  color: #42495b;
  transition: 0.5s;
}

.op-header .op-header-contenedor ul li a::before {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  bottom: 0;
  left: 45%;
  transition: 0.6s ease all;
  -moz-transition: 0.6s ease all;
  -webkit-transition: 0.6s ease all;
}

.op-header .op-header-contenedor ul li a.active::before {
  width: 100%;
  left: 0;
  background: #42495b;
}

.op-header .op-header-contenedor input[type='checkbox'] {
  display: none;
}

.op-header .op-header-contenedor .checkButton {
  font-size: 30px;
  color: #ffffff;
  order: 4;
  cursor: pointer;
  padding: 5px;
  display: none;
}

.op-header .op-header-contenedor .checkButton span {
  height: 3px;
  width: 25px;
  background-color: #2a3443;
  display: block;
  margin: 5px 0 5px 0;
  transition: 0.7s ease-in-out;
  transform: none;
}

.op-header
  .op-header-contenedor
  input[type='checkbox']:checked
  ~ .checkButton
  span:nth-of-type(3) {
  opacity: 0;
}

.op-header
  .op-header-contenedor
  input[type='checkbox']:checked
  ~ .checkButton
  span:nth-of-type(1) {
  transform: translate(0%, 225%) rotate(-45deg);
}

.op-header
  .op-header-contenedor
  input[type='checkbox']:checked
  ~ .checkButton
  span:nth-of-type(2) {
  transform: rotate(45deg);
}

.op-header .op-header-contenedor .op-header-actions {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 885px) {
  .op-header .op-header-contenedor {
    padding: 0 50px;
  }
}

@media (max-width: 576px) {
  .op-header {
    height: 70px;
  }
  .op-header .op-header-contenedor {
    padding: 0 30px;
  }

  .op-header .op-header-contenedor .op-logo {
    height: 70px;
  }

  .op-header .op-header-contenedor .op-logo > a {
    width: 108px;
    height: 25px;
  }

  .op-header .op-header-contenedor ul {
    position: fixed;
    width: 100%;
    height: auto;
    background-color: #ffffff;
    top: 70px;
    left: -120%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    z-index: 300;
    transition: all 0.5s;
  }

  .op-header .op-header-contenedor ul li {
    padding-left: 30px;
    border-bottom: 1px solid #d1d1d1;
    height: 50px;
    display: block;
    width: 100%;
    line-height: 50px;
  }

  .op-header .op-header-contenedor ul li a {
    color: #42495b;
  }

  .op-header .op-header-contenedor ul li a.active,
  .op-header .op-header-contenedor ul li a:hover {
    color: #3644af;
  }

  .op-header .op-header-contenedor ul li a.active::before {
    background-color: transparent;
  }

  .op-header .op-header-contenedor .checkButton {
    display: block;
  }

  .op-header .op-header-contenedor .op-header-actions {
    display: none;
  }

  .op-header .op-header-contenedor input[type='checkbox']:checked ~ ul {
    left: 0;
  }
}
