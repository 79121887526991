.op-wuasho {
  display: grid;
  grid-template-columns: 1fr 1fr;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url('../../assets/images/fonto-washo.png');
  height: calc(100vh);
  width: calc(100% + 200px);
  left: -100px;
  background-color: #de281f;
}
.op-fondo-wuasho {
  position: relative;
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
  margin-right: 0;
  overflow: hidden;
}

.op-fondo-wuasho div {
  width: 70%;
  margin-top: -10% !important;
  margin-left: 12% !important;
  margin-right: 0;
  transform: translateX(15%) translateY(3%) rotateX(0deg) rotateY(0deg)
    rotateZ(39deg);
  text-align: right;
  z-index: 10;
  animation: wuasho-imagen-animado 1500ms ease-in;
}

.op-fondo-wuasho img,
.op-wuasho-promo img {
  position: relative;
  background: transparent;
  max-width: 100%;
  height: auto;
  font-size: 100%;
}

.op-wuasho .op-titulo {
  align-self: center;
  justify-self: center;
  display: flex;
  flex-direction: column;
}

.op-wuasho .op-titulo label {
  text-transform: uppercase;
  font-family: Dosis-ExtraBold;
  font-size: 60px;
  justify-self: center;
  align-self: flex-end;
  color: #ffffff;
  animation: wuasho-titulo-animado 800ms ease-in;
}

.op-wuasho .op-titulo span {
  font-family: Dosis-Bold;
  font-size: 30px;
  align-self: flex-end;
  color: #ffffff;
  animation: wuasho-subtitulo-animado 800ms ease-in;
}

.op-wuasho-descargas {
  display: flex;
  justify-content: space-around;
  width: auto;
  margin-top: 20px;
}

.op-wuasho-descargas a {
  margin-right: 15px;
}
.op-wuasho-descargas img {
  width: 150px;
  height: 50px;
}

.op-wuasho-promo {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 20px;
}

.op-wuasho-promo img {
  transform: rotateY(0deg) rotateZ(-10deg);
  width: 70%;
}

.op-terminos {
  width: 100%;
  font-size: 30px;
  font-family: Dosis-Medium;
  padding: 20px 0;
}

.op-terminos a {
  text-decoration: none;
  color: black;
}

@media (max-width: 576px) {
  .op-wuasho {
    left: -30px;
    width: calc(100% + 60px);
    grid-template-columns: 1fr;
    z-index: 1;
    background-size: auto;
    background-color: #f3f5f9;
  }

  .op-wuasho .op-titulo label {
    font-size: 40px;
    align-self: center;
    color: #000;
  }

  .op-wuasho .op-titulo span {
    align-self: center;
    font-size: 20px;
    color: #000;
  }

  .op-fondo-wuasho div {
    transform: rotateZ(0deg) translateY(10%);
    animation:  wuasho-imagen-mini-animado 1000ms ease-in-out;
  }
}

@keyframes wuasho-subtitulo-animado {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0%);
  }
}

@keyframes wuasho-titulo-animado {
  0% {
    transform: rotate(270deg) translateY(-20%) translateX(-100%);
  }
  100% {
    transform: rotate(0deg) translateY(0%) translateX(0%);
  }
}

@keyframes wuasho-imagen-animado {
  0% {
    transform: translateX(100%) translateY(3%) rotateX(0deg) rotateY(0deg)
    rotateZ(39deg);
  }
  100% {
    transform: translateX(15%) translateY(3%) rotateX(0deg) rotateY(0deg)
    rotateZ(39deg);
  }
}

@keyframes wuasho-imagen-mini-animado {
  0% {
    transform: rotate(270deg);
  }
  100% {
    transform: rotate(0deg);
  }
}